import React, { Component } from 'react'

import '../css/main.css'
import '../css/fonts.css'

import Seo from '../components/SeoHead'
import RedeemContent from '../components/Redeem/'
import { LS_THANKYOU_PAGE_TYPE } from '../constants'

export default class Redeem extends Component {
  render() {
    return (
      <>
        <Seo />

        <RedeemContent
          onSubmit={() => {
            localStorage.setItem(LS_THANKYOU_PAGE_TYPE, 'access');
          }}
          redeemCodeFieldId='mce-REDEEMNEW'
          redeemCodeFieldName='REDEEMNEW'
          // Copied from signup
          creatorToggleFieldName="group[2813][1]"
          creatorToggleFieldId="mce-group[2813]-2813-0"
          formAction="https://skyweaver.us18.list-manage.com/subscribe/post?u=33c3d50aa6c95dcf2fa675797&amp;id=b091f3f3d3"
          utmSource="EGLX"
          creatorToggle
        />
      </>
    )
  }
}
